import { createApp } from 'vue'
import App from './App.vue'
//引入router和vuex（store状态引用）
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import $ from 'jquery'
// import componentsInstall from './components/components-install'
//import { SweetAlert } from 'sweetalert/typings/core'

// import axios from 'axios';
// Vue.config.productionTip = false
// Vue.prototype.$axios = axios;
// const app = createApp(App).use(store);
// app.use(router).use(axios);
// app.use(ElementPlus).use($).mount('#app');

createApp(App).use(store).use(router).use(ElementPlus).use($).mount('#app');     
