import { createRouter, createWebHashHistory } from 'vue-router'
//import HomeView from '../views/HomeView.vue'
import store from '../store'

const routes = [
// {
//   path: '/',
//   name: '',
//   component: () =>import('../components/HelloWorld.vue')
// },
  {
    path: '/',
    name: 'ChatRoom',
    component: () =>import( '../view/ChatRoom.vue'),
    beforeEnter:(to,from,next)=>{
      store.dispatch("tryAutologin",localStorage.getItem('token')).then((logintype)=>{
        console.log(logintype)
        if(!logintype){
          next()
        }
      })
    }
  },
//   {
//     path: '/about',
//     name: 'about',
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
//   },
//   {
//     path: '/userinfo',
//     name: 'UserInfo',
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: () =>import('../views/UserInfo.vue')
//   }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
