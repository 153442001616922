import axios from 'axios';
import Qs from 'qs';
import { createStore } from 'vuex'

export default createStore({
  state: {
    userinfo:{
      //可以只声明类型不定义值
      headImg:"https://81.70.32.224/upload/shortcut.jpg",
      nickName:"闫学惠"
    },
    token:null,
    room_id:"0000",
  },
  getters: {
  },
  mutations: {
    setToken(state,token){
      state.token = token
    },
    editUserinfo(state,user){
      state.userinfo = user;
      console.log("VUEX的state.userinfo",state.userinfo)
    },
    //在vuex中的mutations-变异，变化函数中定义变化函数方法saveUserinfo
    saveUserinfo(state,user){
      state.userinfo = user
    },
    saveUserlist(state,userlist){
      state.userlist = userlist
    },
    saveRoomId(state,roomid){
      state.room_id=roomid
    },
  },
  actions: {
    async tryAutologin({ commit },token){
      await axios({
        method:'POST',
        url:'https://81.70.32.224/api_json/userinfo/',
        data:Qs.stringify({
          'token':token
        }),
      }).then((res)=>{
        let userinfo = res.data
        if(userinfo.nickName.length>=0){
          this.loginType = false
        }
        commit("saveUserinfo",userinfo)
      })
      return this.loginType
    },
    async tryAutoUserlist({ commit },token){
      axios({
        method:'post',
        url:"https://81.70.32.224/api_json/userlist/",
        data: Qs.stringify({
            token:token
        })
        }).then((res)=>{
            console.log("查询后返回的用户列表",res.data)
            commit("saveUserlist",res.data)
        })
    },
    async tryRoomId({commit},room_id){
      commit("saveRoomId",room_id)
    },
  },
  modules: {
  }
})
