<template>
<router-view></router-view>
</template>

<script>
export default{
    computed:{
        token(){
            return this.$store.state.token
        }
    },
    watch:{
        token(newvalue,oldvalue){
            console.log("--------APP.vue-token监听-------",newvalue,oldvalue)
            this.$store.dispatch("tryAutologin",newvalue[0])
        }
    },
    mounted(){

    }
}
</script>

<style>

</style>
